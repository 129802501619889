import Experience from "../Experience.js";
import Environment from "./Environment.js";
import MainStar from "./MainStar.js";
import Stars from "./Stars.js";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // Wait for resources
    this.resources.on("ready", () => {
      // Setup
      this.environment = new Environment();
      this.stars = new Stars();
      this.mainStar = new MainStar();
    });
  }

  update() {
    this.stars?.update();
    this.mainStar?.update();
  }
}
