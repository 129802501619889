import gsap from "gsap";
import { GET_RetrieveAllStars, GET_testing, POST_testing } from "../services";
import Experience from "./Experience";
import MyStar from "./World/MyStar";

export default class UI {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;

    this.init();
  }

  init() {
    this.initDOM();
    this.initEventListeners();
    this.initStarCTA();
  }

  initDOM() {
    this.CTA__GetTesting = document.getElementById("cta__GET_testing");
    this.CTA__PostTesting = document.getElementById("cta__POST_testing");
    this.CTA__GETAllStars = document.getElementById("cta__GET_allStars");
    this.CONTAINER__RIGHT_PANEL = document.querySelector(
      ".right__panel-container"
    );
    this.CTA__AddStar = document.querySelector(".CTA__add-star");
    this.CTA__AddStarContainer = document.querySelector(
      ".CTA__add-star-container"
    );
    this.hidder = document.querySelector(".hidder");
  }

  showDOM(element, isFlex) {
    if (isFlex === undefined) {
      isFlex = false;
    }

    if (isFlex) {
      element.style.display = "flex";
    } else {
      element.style.display = "initial";
    }
  }

  hideDOM(element) {
    element.style.display = "none";
  }

  initStarCTA() {
    this.hideDOM(this.CTA__AddStar);
    this.hideDOM(this.CTA__AddStarContainer);

    const userLS = window.localStorage.getItem("user");

    if (userLS === null) {
      this.showDOM(this.CTA__AddStar);
      this.showDOM(this.CTA__AddStarContainer, true);
    }
  }

  initEventListeners() {
    this.CTA__GetTesting?.addEventListener("click", this.GETTesting);
    this.CTA__PostTesting?.addEventListener("click", this.POSTTesting);
    this.CTA__GETAllStars?.addEventListener("click", this.GET_RetrieveAllStars);
    this.CTA__AddStar?.addEventListener("click", () => this.initForm(this));
    this.hidder?.addEventListener("click", () => {
      this.hideHidder();
      gsap.to(this.CONTAINER__RIGHT_PANEL, {
        x: "100%",
      });
    });
  }

  showHidder() {
    this.hidder.classList.add("active");
  }

  hideHidder() {
    this.hidder.classList.remove("active");
  }

  glitchSequence() {
    const activeGlitchPass = () => {
      _this.experience.renderer.glitchPass.enabled = true;
    };

    const unactiveGlithPass = () => {
      _this.experience.renderer.glitchPass.enabled = false;
    };

    activeGlitchPass();
    setTimeout(() => unactiveGlithPass(), 300);
    setTimeout(() => activeGlitchPass(), 500);
    setTimeout(() => unactiveGlithPass(), 700);
    setTimeout(() => activeGlitchPass(), 1300);
    setTimeout(() => unactiveGlithPass(), 1600);
    setTimeout(() => activeGlitchPass(), 1800);
    setTimeout(() => unactiveGlithPass(), 2000);
  }

  initForm(_this) {
    _this.showHidder();
    gsap.to(_this.CONTAINER__RIGHT_PANEL, {
      x: 0,
    });
    var connect_button = new WMGConnect("", {
      opt_in_thank_you_enabled: false,
      opt_in_form_target: "#formulaire",
    });

    connect_button.setCallback(function (connect_data) {
      gsap.to(_this.CONTAINER__RIGHT_PANEL, {
        x: "100%",
      });
      _this.hideDOM(_this.CTA__AddStar);
      _this.hideHidder();
      _this.initMyStar();
      _this.POSTTesting(connect_data.user.id);

      _this.glitchSequence();
    });
  }

  initMyStar() {
    this.experience.myStar = new MyStar();
  }

  GETTesting() {
    const response = GET_testing();
    response
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  POSTTesting(pseudo) {
    const response = POST_testing(pseudo + "--" + Date.now());
    response
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  GET_RetrieveAllStars() {
    const response = GET_RetrieveAllStars();
    response
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }
}
