import { Lensflare, LensflareElement } from "three/addons/objects/Lensflare.js";

import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";

export default class MainStar {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    this.starCallbacks = []; // Stockage des callbacks pour chaque étoile
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    // Resource
    this.starTexture1 = this.resources.items.starLens1;
    this.starTexture2 = this.resources.items.starLens2;

    this.setMaterial();
    this.setModel();
    this.setMesh();
    this.setupEvents(); // Gérer les clics
  }

  setupEvents() {
    window.addEventListener("pointerdown", (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.experience.camera.instance);

      const intersects = this.raycaster.intersectObject(this.mainParticle);
      if (intersects.length > 0) {
        const intersect = intersects[0];
        const index = intersect.index;

        if (index !== undefined) {
          // Animer la taille de l'étoile cliquée
          const scales = this.particlesGeometry.attributes.scale.array;
          gsap.to(scales, {
            [index]: scales[index] * 2, // Doubler la taille
            duration: 1,
            onUpdate: () => {
              this.particlesGeometry.attributes.scale.needsUpdate = true;
            },
            onComplete: () => {
              // Réduire à la taille initiale
              gsap.to(scales, {
                [index]: scales[index] / 2,
                duration: 1,
                onUpdate: () => {
                  this.particlesGeometry.attributes.scale.needsUpdate = true;
                },
              });
            },
          });
        }
      }
    });
  }

  setMaterial() {
    this.particlesMaterial = new THREE.ShaderMaterial({
      vertexShader: `
            uniform float uTime;
            attribute float scale;
            attribute float timeOffset;
            attribute float noiseOffset; // Nouvel attribut pour le bruit
            varying vec3 vColor;

            // Fonction pour générer un bruit pseudo-aléatoire
            float random(vec3 point) {
                return fract(sin(dot(point, vec3(12.9898, 78.233, 45.164))) * 43758.5453);
            }

            void main() {

                // Calcul du bruit avec la position
                float noise = random(position + vec3(noiseOffset)); // Bruit basé sur la position et le bruit offset

                // Calcul de la pulsation avec bruit
                float pulsate = max(sin(uTime * 1.0 + timeOffset + noise * 2.0), 0.4) * 1.0 + 1.0;

                vec4 modelPosition = modelViewMatrix * vec4(position, 1.0);
                gl_PointSize = (pulsate * scale * 100.0) / -modelPosition.z;

                gl_Position = projectionMatrix * modelPosition;
                vColor = color;
            }
        `,
      fragmentShader: `
            uniform sampler2D uTexture;  // La texture
            varying vec3 vColor;  // Couleur de la particule calculée dans le vertex shader

            void main() {
              // Light point: Calculer l'intensité lumineuse en fonction de la distance
              float strength = distance(gl_PointCoord, vec2(0.5));  // Calculer la distance à partir du centre
              strength = 1.0 - strength;  // L'intensité est plus forte au centre
              strength = pow(strength, 10.0);  // Accentuer l'intensité près du centre

              // Récupérer la couleur de la texture au point de la particule
              vec4 texColor = texture2D(uTexture, gl_PointCoord);
              if (texColor.a < 0.1) discard;  // Si la texture est trop transparente, ne pas afficher le pixel

              // Réduire l'intensité de la texture en la multipliant par un facteur
              texColor.rgb *= 50.0;  // Réduire la luminosité de la texture (valeur entre 0.0 et 1.0)
              // texColor.r = 1;
              // Mélanger la couleur de la particule avec la texture
              vec3 finalColor = mix(vColor, texColor.rgb, 0.3);  // Mélange à 50% entre la couleur de la particule et la texture

              // Final color avec le facteur d'intensité
              gl_FragColor = vec4(finalColor * strength, texColor.a);  // Appliquer la luminosité calculée à la couleur finale
              
              #include <colorspace_fragment>  // Gérer la conversion de couleur, si nécessaire
            }
        `,
      uniforms: {
        uTexture: { value: this.starTexture1 }, // Transmettre la texture
        uTime: { value: 0 },
      },
      // transparent: true,
      // depthTest: false,
      // vertexColors: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
      vertexColors: true,
    });
  }

  setModel() {
    // Geometry
    this.particlesGeometry = new THREE.BufferGeometry();
    const count = 1;

    const insideColor = new THREE.Color("red");
    const outsideColor = new THREE.Color("red");

    const RADIUS = 200;

    const positions = new Float32Array(count * 3); // Positions
    const colors = new Float32Array(count * 3); // Couleurs
    const scales = new Float32Array(count); // Échelles individuelles
    const timeOffsets = new Float32Array(count); // Décalages temporels

    for (let i = 0; i < count; i++) {
      // Positions aléatoires
      positions[i * 3] = (Math.random() - 0.5) * RADIUS;
      positions[i * 3 + 1] = (Math.random() - 0.5) * RADIUS;
      positions[i * 3 + 2] = (Math.random() - 0.5) * RADIUS;

      // Couleurs
      // const starHue = Math.random() * 0.15;
      // const saturation = Math.random() * 0.4 + 0.6;
      // const lightness = Math.random() * 0.3 + 0.7;
      // const color = new THREE.Color().setHSL(starHue, saturation, lightness);

      // Calculer la distance depuis le centre (0, 0, 0)
      const distanceFromCenter = Math.sqrt(
        Math.abs(Math.pow(positions[i * 3], 2)) +
          Math.abs(Math.pow(positions[i * 3 + 1], 2)) +
          Math.abs(Math.pow(positions[i * 3 + 2], 2))
      );

      // Lerp basé sur cette distance, en normalisant par rapport au rayon
      const lerpFactor = distanceFromCenter / RADIUS;
      const mixedColor = insideColor.clone();
      mixedColor.lerp(outsideColor, lerpFactor);

      console.log(mixedColor);
      colors[i * 3] = mixedColor.r;
      colors[i * 3 + 1] = mixedColor.g;
      colors[i * 3 + 2] = mixedColor.b;

      // Taille initiale
      scales[i] = 100;

      // Décalage temporel aléatoire
      timeOffsets[i] = Math.random() * Math.PI * 2; // Décalage entre 0 et 2π
    }

    this.particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    this.particlesGeometry.setAttribute(
      "color",
      new THREE.BufferAttribute(colors, 3)
    );
    this.particlesGeometry.setAttribute(
      "scale",
      new THREE.BufferAttribute(scales, 1)
    );
    this.particlesGeometry.setAttribute(
      "timeOffset",
      new THREE.BufferAttribute(timeOffsets, 1)
    );
  }

  setMesh() {
    // Points
    this.mainParticle = new THREE.Points(
      this.particlesGeometry,
      this.particlesMaterial
    );
    this.scene.add(this.mainParticle);

    this.mainParticle.scale.set(1, 1, 1);

    gsap.to(this.mainParticle.scale, {
      x: 1,
      y: 1,
      z: 1,
      duration: 6,
      delay: 0.3,
    });
  }

  setupEvents() {
    window.addEventListener("pointerdown", (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.experience.camera.instance);

      const intersects = this.raycaster.intersectObject(this.mainParticle);
      if (intersects.length > 0) {
        const intersect = intersects[0];
        const index = intersect.index;

        if (index !== undefined) {
          // Animer la taille de l'étoile cliquée
          const scales = this.particlesGeometry.attributes.scale.array;
          gsap.to(scales, {
            [index]: scales[index] * 2, // Doubler la taille
            duration: 1,
            onUpdate: () => {
              this.particlesGeometry.attributes.scale.needsUpdate = true;
            },
            onComplete: () => {
              // Réduire à la taille initiale
              gsap.to(scales, {
                [index]: scales[index] / 2,
                duration: 1,
                onUpdate: () => {
                  this.particlesGeometry.attributes.scale.needsUpdate = true;
                },
              });
            },
          });
        }
      }
    });
  }

  update() {
    if (this.particlesMaterial) {
      this.particlesMaterial.uniforms.uTime.value = this.time.elapsed * 0.001;
    }
  }
}
