import * as THREE from "three";
import Experience from "./Experience.js";
import { FlyControls } from "./CustomFlyControls.js";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.clock = new THREE.Clock(); // Gestion du temps pour FlyControls.

    this.setInstance(); // Crée l'instance de la caméra.
  }

  // Configuration de la caméra principale
  setInstance() {
    this.instance = new THREE.PerspectiveCamera(
      45, // Champ de vision
      this.sizes.width / this.sizes.height, // Rapport largeur/hauteur
      0.1, // Plan proche
      5000 // Plan éloigné
    );
    this.instance.position.set(0, 0, 10); // Position initiale de la caméra
    this.scene.add(this.instance); // Ajout à la scène
  }

  // Configuration des FlyControls
  setControls(renderer) {
    console.log(renderer);
    this.controls = new FlyControls(this.instance, this.canvas);

    this.controls.movementSpeed = 5; // Vitesse de déplacement réduite pour le mobile
    this.controls.rollSpeed = Math.PI / 6; // Rotation plus lente
    this.controls.autoForward = false;
    this.controls.dragToLook = true;

    this.isTouching = false;
    this.touchStartX = 0;
    this.touchStartY = 0;

    // Écouteurs tactiles
    this.canvas.addEventListener("touchstart", (event) => {
      this.isTouching = true;
      this.touchStartX = event.touches[0].clientX;
      this.touchStartY = event.touches[0].clientY;
    });

    this.canvas.addEventListener("touchmove", (event) => {
      if (!this.isTouching) return;

      const touch = event.touches[0];
      const deltaX = touch.clientX - this.touchStartX;
      const deltaY = touch.clientY - this.touchStartY;

      // Appliquer les rotations
      this.controls.rotateLeft(deltaX * 0.002);
      this.controls.rotateUp(-deltaY * 0.002);

      // Appliquer le déplacement avant/arrière
      if (Math.abs(deltaY) > 10) {
        this.controls.moveForward(deltaY * 0.01);
      }

      this.touchStartX = touch.clientX;
      this.touchStartY = touch.clientY;
    });

    this.canvas.addEventListener("touchend", () => {
      this.isTouching = false;
    });
  }

  // Gestion du redimensionnement de l'écran
  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix(); // Met à jour la matrice de projection
  }

  // Mise à jour des contrôles dans la boucle d'animation
  update() {
    const delta = this.clock.getDelta(); // Temps écoulé depuis la dernière frame
    if (this.controls) {
      this.controls.update(delta); // Met à jour FlyControls
    }
  }
}
