const GET_testing = async () => {
    return await fetch(`http://127.0.0.127:3487/testing`)
}

const POST_testing = async (pseudo) => {
    return await fetch(`http://127.0.0.127:3487/star`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ pseudo }),
    });
}

const GET_RetrieveAllStars = async () => {
    return await fetch(`http://127.0.0.127:3487/stars`);
}

export { GET_testing, POST_testing, GET_RetrieveAllStars };